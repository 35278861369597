import React from 'react';

import { Badge } from 'react-bootstrap';

function SelectMealFilter({ options, selectedFilterItems, setFilter }) {
    return (
        options.map((option, index) => (
            <Badge className='mx-1' pill
                bg={selectedFilterItems.some(selectedItem => selectedItem === option) ? 'success' : 'secondary'}
                onClick={() => {
                    if (selectedFilterItems.some(selectedItem => selectedItem === option)) {
                        setFilter(selectedFilterItems.filter((selectedOption) => selectedOption !== option))
                    } else {
                        setFilter([...selectedFilterItems, option])
                    }
                }} 
                key={index}>
                {option}
            </Badge>
        ))
    );
};

export default SelectMealFilter;
