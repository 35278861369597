import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form, Spinner } from 'react-bootstrap';
import axios from 'axios';

function DisplayIngredientsModal(props) {
    const [loading, setLoading] = useState(true);
    const [ingredients, setIngredients] = useState([]);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        axios.get('/get-shopping-list', { params: { planId: props.planId } })
            .then(response => {
                setIngredients(response.data.success);
                setErrors(response.data.error);
            })
    }, []);

    useEffect(() => {
        if (ingredients && ingredients.length > 0) {
            setLoading(false);
        }
    }, [ingredients]);

    return (
        <Modal {...props} className='mt-5' aria-labelledby="contained-modal-title-vcenter" >
            {loading && <Spinner className='align-self-center' animation="grow" />}
            {!loading &&
                <Modal.Dialog>
                    <Modal.Header closeButton>
                        <Modal.Title>Shopping List</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {errors.filter(er => er.name).length > 0 && <Form.Group>
                                <Form.Text> Some Recipes could not be read:
                                    <ul>
                                        {
                                            errors.filter(er => er.name).map(er => <li>{er.name}</li>)
                                        }
                                    </ul>
                                </Form.Text>
                            </Form.Group>}
                            <Form.Group>
                                <Form.Label>Ingredients</Form.Label>
                                <Form.Text>
                                    <ul>{ingredients.map(i => <li>{i.ingredient}</li>)}</ul>
                                </Form.Text>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal.Dialog >
            }
        </Modal >
    );
}

export default DisplayIngredientsModal;
