import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import React from 'react';
import { Button, Navbar } from 'react-bootstrap';
import { ArrowClockwise, ArrowLeft, ArrowRight, BoxArrowLeft, ListCheck, PlusSquare } from 'react-bootstrap-icons';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import features from '../features';
import constants from '../constants';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function TopBar({ mealPlan, getNextMealPlan, getPrevMealPlan, setAddRecipeModalShow, setIngredientsModalShow }) {
    const { USER_ID_COOKIE_PARAM, HOME_ID_COOKIE_PARAM, CURRENT_PLAN_COOKIE_PARAM } = constants.cookieNames;
    const { addRecipeTopNavBar, refreshMenuOptions, signOutButton, getIngredientsButton } = features;

    return (
        <Navbar sticky='top' className="bg-body-tertiary pt-5">
            <Nav activeKey="/home">
                <Nav.Item onClick={() => getPrevMealPlan()}>
                    <Button className='btn-md' variant='outline'>
                        <ArrowLeft />
                    </Button>
                </Nav.Item>
                <Nav.Item>
                    {mealPlan.forStartOfWeek && <h1 className='pt-1 text-nowrap'>{moment(mealPlan.forStartOfWeek).format(' Do MMM YYYY')}</h1>}
                    {!mealPlan.forStartOfWeek && <Spinner animation="grow" />}
                </Nav.Item>
                <Nav.Item onClick={() => getNextMealPlan()}>
                    <Button className='btn-md' variant='outline'>
                        <ArrowRight />
                    </Button>
                </Nav.Item>
                {refreshMenuOptions &&
                    <Nav.Item onClick={() => getNextMealPlan()}>
                        <Button className='btn-md' variant='outline'>
                            <ArrowClockwise />
                        </Button>
                    </Nav.Item>}
                {addRecipeTopNavBar &&
                    <Nav.Item onClick={() => setAddRecipeModalShow(true)}>
                        <Button className='btn-md' variant='outline'>
                            <PlusSquare />
                        </Button>
                    </Nav.Item>}
                {signOutButton && 
                    <Nav.Item onClick={() => {
                        cookies.remove(USER_ID_COOKIE_PARAM);
                        cookies.remove(HOME_ID_COOKIE_PARAM);
                        cookies.remove(CURRENT_PLAN_COOKIE_PARAM);
                        window.location.reload();
                    }}>
                        <Button className='btn-md' variant='outline'>
                            <BoxArrowLeft />
                        </Button>
                    </Nav.Item>}
                {getIngredientsButton && 
                    <Nav.Item  onClick={() => setIngredientsModalShow(true)}>
                        <Button className='btn-md' variant='outline'>
                            <ListCheck />
                        </Button>
                    </Nav.Item>}
            </Nav>
        </Navbar>
    )
}

export default TopBar;
