import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Button, Row, Col, Form, FloatingLabel, Container, CloseButton } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';

function SignInModal(props) {
    const [userId, setUserId] = useState('');
    const [newUserId, setNewUserId] = useState('');
    const [name, setName] = useState('');
    const [home, setHome] = useState('');
    const [showCreateUser, setShowCreateUser] = useState(false);

    const { users, onSignIn, onCreateUser } = props;

    useEffect(() => {
        if (users.length > 0) {
            setUserId(users[0]._id);
        }
    }, [users]);

    return (
        <Modal {...props} className='py-5' aria-labelledby="contained-modal-title-vcenter" >
            <Modal.Dialog>
                <Modal.Header>
                    {!showCreateUser && <Modal.Title>Sign In</Modal.Title>}
                    {showCreateUser && <><Modal.Title>Create New User</Modal.Title>
                        <CloseButton className='align-top' onClick={() => setShowCreateUser(!showCreateUser)}></CloseButton></>}
                </Modal.Header>
                <Modal.Body>
                    {/* <Alert variant='warning'>
                        {' Honour System is in place! Don\'t be a dick :)'}
                    </Alert> */}
                    <Container>
                        {!showCreateUser &&
                            <Form onSubmit={() => onSignIn(userId)}>
                                <Form.Group as={Row}>

                                    <Col lg={9} xs={9}>
                                        <Form.Select defaultValue={users[0]} size='lg' disabled={showCreateUser} onChange={(e) => setUserId(e.target.value)}>
                                            {users && users.map((user) => (
                                                <option value={user._id}>{user.name}</option>
                                            ))}
                                        </Form.Select></Col>
                                    <Col lg={3} xs={2}>
                                        <Button className='btn-lg' variant='outline-primary' onClick={() => setShowCreateUser(!showCreateUser)}><Plus></Plus></Button>
                                    </Col>
                                    <Row className='text-right my-2 pt-5'>
                                        <Button className='float-end' variant="success" type="submit">
                                            Sign In
                                        </Button>
                                    </Row>
                                </Form.Group>
                            </Form>
                        }
                        {showCreateUser &&
                            <Form onSubmit={() => onSignIn(newUserId)}>
                                <Form.Group as={Row}>
                                    <Col sm="12">
                                        <FloatingLabel label='Name'>
                                            <Form.Control
                                                type="text"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='my-2'>
                                    <Col sm="12">
                                        <FloatingLabel label='Home' >
                                            <Form.Control
                                                type="text"
                                                value={home}
                                                onChange={(e) => setHome(e.target.value)}
                                            />
                                        </FloatingLabel>
                                        <Form.Text id="passwordHelpBlock" muted>
                                            Home is how you share recipes amongst family. The name doesn't matter too much as long as you have the same one.
                                        </Form.Text>
                                    </Col>
                                </Form.Group>
                                <Row className='text-right my-2'>
                                    <Button className='float-end' variant="success" onClick={async () => {
                                        const userId = await onCreateUser(name, home);
                                        setNewUserId(userId);
                                        window.location.reload();
                                    }}>
                                        Create User
                                    </Button>
                                </Row>
                            </Form>
                        }

                    </Container>
                </Modal.Body>
            </Modal.Dialog >
        </Modal >
    )
}

export default SignInModal;