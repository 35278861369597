import React from 'react';

import { Badge } from 'react-bootstrap';

const effortColours = {
    'Low': 'success',
    'Medium': 'warning',
    'High': 'danger'
}

function SummaryPills({ pillContent, effort }) {
    return (<>
        {pillContent.map((pill, index) => (
            <Badge className='mx-1' pill bg='secondary' key={index}>{pill}</Badge>
        ))}
        {<Badge className='mx-1' pill bg={effortColours[effort ? effort : 'Medium']} >{effort ? effort : 'Medium'} Effort</Badge>}
    </>);
};

export default SummaryPills;
