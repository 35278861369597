import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Container, Navbar, Toast, ToastBody } from 'react-bootstrap';
import { Plus, PlusCircle } from 'react-bootstrap-icons';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Cookies from 'universal-cookie';
import features from '../features';
import { socket } from '../socket';
import AddRecipeModal from './AddRecipeModal';
import EditModal from './EditModal';
import Item from './Item';
import TopBar from './TopBar';
import constants from '../constants'
import ReactGA from 'react-ga4';
import DisplayIngredientsModal from './DisplayIngredientsModal';

function RecipeList() {
    const cookies = new Cookies();

    const dow = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const { regenerateButtonEnabled, addRecipeFloatingButton, addRecipeBottomNavBar, enableWebSockets } = features;

    const [items, setItems] = useState([]);
    const [chosenItems, setChosenItems] = useState([]);
    const [editItemIndex, setEditItemIndex] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [addRecipeModalShow, setAddRecipeModalShow] = useState(false);
    const [ingredientsModalShow, setIngredientsModalShow] = useState(false);
    const [toastShow, setToastShow] = useState(false);
    const [mealPlan, setMealPlan] = useState({});
    const [toastMessage, setToastMessage] = useState('Recipe Saved Successfully');
    const [isToastError, setIsToastError] = useState(true);

    const { CURRENT_PLAN_COOKIE_PARAM } = constants.cookieNames;

    useEffect(() => {
        function getChoices() {

            const params = {};
            if (cookies.get(CURRENT_PLAN_COOKIE_PARAM)) {
                params.currentPlan = cookies.get(CURRENT_PLAN_COOKIE_PARAM);

            }

            axios.get('/meal-plan', { params })
                .then(response => {
                    const plan = response.data;
                    setMealPlan(plan);
                })
                .catch(error => {
                    console.error('Error fetching chosen items:', error)
                });
        }

        function onShuffle(updatedItems) {
            if (cookies.get(CURRENT_PLAN_COOKIE_PARAM) === updatedItems._id) {
                getChoices();
            }
        }

        if (enableWebSockets) {
            socket.on('shuffle', onShuffle);
        }

        getChoices();
        return () => {
            if (enableWebSockets) {
                socket.off('shuffle', onShuffle);
            }
        };

    }, []);

    useEffect(() => {
        if (mealPlan && mealPlan._id) {
            cookies.set(CURRENT_PLAN_COOKIE_PARAM, mealPlan._id)
            setChosenItems(mealPlan.mealPlan);
        }
    }, [mealPlan]);

    const getNextMealPlan = () => {
        ReactGA.gtag('event', 'next-meal-plan')

        mealPlan.nextPlan ?
            axios.get('/get', { params: { planId: mealPlan.nextPlan } })
                .then(nextMealPlan => {
                    setMealPlan(nextMealPlan.data);
                }) :
            axios.get('/next', { params: { planId: mealPlan._id } })
                .then(nextMealPlan => {
                    setMealPlan(nextMealPlan.data);
                })
    }

    const getPrevMealPlan = () => {
        ReactGA.gtag('event', 'prev-meal-plan')

        mealPlan.prevPlan ?
            axios.get('/get', { params: { planId: mealPlan.prevPlan } })
                .then(nextMealPlan => {
                    setMealPlan(nextMealPlan.data);
                }) :
            axios.get('/prev', { params: { planId: mealPlan._id } })
                .then(nextMealPlan => {
                    setMealPlan(nextMealPlan.data);
                });
    }

    const handleShuffle = (index) => {
        const currentItem = chosenItems[index];
        // TODO: don't know why we need to force an array here, it should already be one.
        const excludeCuisines = Array.from(chosenItems).map(item => item.cuisine).join(',');

        ReactGA.gtag('event', 'shuffle-recipe')

        axios.get('/shuffle', { params: { excludeCuisines, currentProtein: currentItem.protein, index, mealPlanId: mealPlan._id } })
            .then(updatedMealPlan => {
                setMealPlan(updatedMealPlan.data)
            })
            .catch(error => {
                setToastMessage('Unable to shuffle');
                setIsToastError(true);
                setToastShow(true);

                console.error('Error shuffling items:', error)
            });
    };

    const handleEdit = (index) => {
        ReactGA.gtag('event', 'select-recipe')

        setEditItemIndex(index);
        setModalShow(true);
        axios.get('/items')
            .then(response => setItems(response.data))
            .catch(error => console.error('Error fetching items:', error));
    };

    const handleLock = (index) => {
        ReactGA.gtag('event', 'lock-recipe')

        axios.post('/lock', { index, mealPlanId: mealPlan._id })
            .then(response => {
                const updatedItems = [...chosenItems];
                updatedItems[index].locked = !updatedItems[index].locked;
                setChosenItems(updatedItems);
            })
            .catch(error => console.error('Error locking item:', error));
    };

    const handleItemSelection = (item) => {
        ReactGA.gtag('event', 'add-new-recipe')

        const updatedItems = [...chosenItems];
        updatedItems[editItemIndex] = item;
        setChosenItems(updatedItems);
        axios.post('/save', { data: updatedItems }, { params: { mealPlanId: mealPlan._id } })
            .then(() => {
                setEditItemIndex(null)
                setAddRecipeModalShow(false)

                setToastMessage('Recipe Saved Successfully')
                setIsToastError(false)
                setToastShow(true)
            })
            .catch(error => console.error('Error saving items:', error));
    };

    const handleCategorySelection = (category, value, editItemIndex) => {
        axios.get('/shuffle-category?', { params: { field: category, value, mealPlanId: mealPlan._id, index: editItemIndex } })
            .then(resp => setEditItemIndex(null));
    }

    return (
        <>
            <TopBar
                mealPlan={mealPlan}
                getNextMealPlan={getNextMealPlan}
                getPrevMealPlan={getPrevMealPlan}
                setAddRecipeModalShow={setAddRecipeModalShow}
                setIngredientsModalShow={setIngredientsModalShow}
            />

            <Toast bg={isToastError ? 'danger' : 'success'} className="start-50 bottom-0 translate-middle-x text-white fixed-bottom z-3 mb-5" onClose={() => setToastShow(false)} show={toastShow} autohide delay={3000}>
                <ToastBody>{toastMessage}</ToastBody>
            </Toast>

            <Row xs={1} md={2} className='g-1'>
                {chosenItems && chosenItems.map((item, index) => (
                    <Col key={index}>
                        <Item
                            dow={dow[index]}
                            key={index}
                            item={item}
                            onShuffle={() => handleShuffle(index)}
                            onEdit={() => handleEdit(index)}
                            onLock={() => handleLock(index)}
                        />
                    </Col>
                ))}

                {chosenItems && chosenItems.length === 0 && (
                    <>
                        <Col className='px-4 py-4'>
                            <h4>There are no recipes yet.</h4>
                            <h4>Add some to generate a plan.</h4>

                            <Row xs={12} md={12} className='pt-4'>
                                <Button variant='success' onClick={() => {
                                    axios.get('/generate-meal-plan')
                                        .then(response => {
                                            const plan = response.data;
                                            setMealPlan(plan);
                                        })
                                        .catch(error => {
                                            console.error('Error fetching chosen items:', error)
                                        });
                                }}> Regenerate plan</Button>
                            </Row>
                        </Col>
                    </>
                )}
            </Row>

            {regenerateButtonEnabled &&
                <Row xs={12} md={12} className='pt-4'>
                    <Button> Regenerate plan</Button>
                </Row>}

            {editItemIndex !== null && (
                <EditModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    items={items}
                    onClose={() => setEditItemIndex(null)}
                    onItemSelected={handleItemSelection}
                    onCategorySelect={(category) => (item) => handleCategorySelection(category, item, editItemIndex)}
                    onRecipeAdded={handleItemSelection}
                />
            )}

            {addRecipeModalShow && <AddRecipeModal
                onRecipeAdded={handleItemSelection}
                show={addRecipeModalShow}
                onClose={() => setAddRecipeModalShow(false)}
                onHide={() => setAddRecipeModalShow(false)}
            />}

            {ingredientsModalShow && <DisplayIngredientsModal
                planId={mealPlan._id}
                show={ingredientsModalShow}
                onClose={() => setIngredientsModalShow(false)}
                onHide={() => setIngredientsModalShow(false)}
            />}

            {addRecipeFloatingButton &&
                <Row className='fixed-bottom justify-content-end' md={1}>
                    <Col className='me-4' lg={3} xs={3} >
                        <Badge pill className='btn-lg mb-5 min-vw-25 min-vh-25' onClick={() => setAddRecipeModalShow(true)}>
                            <PlusCircle width={'100%'} height={'100%'}></PlusCircle>
                        </Badge>
                    </Col>
                </Row>}

            {addRecipeBottomNavBar &&
                <Navbar sticky='bottom' className="bg-body-tertiary">
                    <Container className='pb-2'>
                        {/* <Navbar.Brand href="#home">Navbar with text</Navbar.Brand> */}
                        <Navbar.Toggle />
                        <Navbar.Collapse className="justify-content-center">
                            <Navbar.Text>
                                <Button className='btn-lg' onClick={() => setAddRecipeModalShow(true)}>
                                    <Plus ></Plus>
                                </Button>
                            </Navbar.Text>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>}
        </>
    )
}

export default RecipeList;
