import React from 'react';
import { Card, Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import ButtonRow from './ButtonRow';
import SummaryPills from './SummaryPills';

function ImageCardView({ dow, item, onShuffle, onEdit, onLock }) {
    const pillContent = [item.protein,
    item.cuisine,
    item.carb
    ];

    const image = item.image ? item.image : "Spaghetti-Bolognese-square-FS-0204-500x500.jpg";

    return (
        <Card className="item mx-3 mt-1">
            {item.url && <a target='_blank' href={item.url}>
                <Card.Img src={image} className='object-fit-cover' style={{ height: 394 + 'px' }} alt={item.name} />
                <Card.ImgOverlay>
                    <Card.Text className='mx-2 my-2 justify-content-end'>
                        <SummaryPills pillContent={pillContent} effort={item.effort} />
                    </Card.Text>
                </Card.ImgOverlay>
            </a>}
            {!item.url &&
                <>
                    <Card.Img src={image} className='object-fit-cover' style={{ height: 394 + 'px' }} alt={item.name} />
                    <Card.ImgOverlay>
                        <Card.Text className='mx-2 my-2 justify-content-end'>
                            <SummaryPills pillContent={pillContent} effort={item.effort} />
                        </Card.Text>
                    </Card.ImgOverlay>
                </>
            }

            <Card.Header as="h1">
                <Row>
                    <Col xs={9} md={9}>
                        <span className='text-nowrap'>{dow}</span>
                        <br />
                        <span class='fw-lighter' >{item.name}</span>
                        {item.book &&
                            (<>
                                <br />
                                <span class='fw-lighter'>{item.book}{item.page && ` - page ${item.page}`}</span>
                            </>
                            )
                        }
                    </Col>
                    <Col className="d-flex justify-content-end text-nowrap z-0" xs={3} md={3}>
                        <ButtonRow item={item} onEdit={onEdit} onShuffle={onShuffle} onLock={onLock} />
                    </Col>
                </Row>
            </Card.Header>
        </Card>
    );
}

export default ImageCardView;
