import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

function SelectableStringModalContent(props) {
    return (
        <Container>
            {props.strings.sort().map((item, index) => (
                <Row key={index}>
                    <Button className='m-1' variant='light' key={index} onClick={() => props.onCategorySelect(item, index)}>
                        {item}
                    </Button>
                </Row>
            ))}
        </Container>
    )
}

export default SelectableStringModalContent;