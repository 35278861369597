import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import SelectMealFilter from '../../SelectMealFilter';

function SelectMealModalContent(props) {
    const [cusineFilter, setCuisineFilter] = useState([]);
    const [cusineOptions, setCusineOptions] = useState([]);

    const [proteinFilter, setProteinFilter] = useState([]);
    const [proteinOptions, setProteinOptions] = useState([]);
    
    const [carbFilter, setCarbFilter] = useState([]);
    const [carbOptions, setCarbOptions] = useState([]);
    
    const [itemsToDisplay, setItemsToDisplay] = useState([]);
    const [baseItems, setBaseItems] = useState([]);

    useEffect(() => {
        axios.get('/cuisines')
            .then(response => {
                setCusineOptions(response.data)
            })

        axios.get('/proteins')
            .then(response => {
                setProteinOptions(response.data)
            })

        axios.get('/carbs')
            .then(response => {
                setCarbOptions(response.data)
            })
    }, [])

    useEffect(() => {
        setBaseItems(props.items);
    }, [props.items])

    useEffect(() => {
        if (baseItems.length > 0) {
            setItemsToDisplay(
                baseItems
                    .filter(item => cusineFilter.length === 0 || cusineFilter.some((cuisine) => item.cuisine === cuisine))
                    .filter(item => proteinFilter.length === 0 || proteinFilter.some((protein) => item.protein === protein))
                    .filter(item => carbFilter.length === 0 || carbFilter.some((carb) => item.carb === carb))
            );
        } else {
            setItemsToDisplay(baseItems);
        }
    }, [cusineFilter, proteinFilter, carbFilter, baseItems])

    const sortFunc = (a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    }

    return (
        <>
            <Container className='pb-4'>
                <SelectMealFilter options={cusineOptions} setFilter={setCuisineFilter} selectedFilterItems={cusineFilter}></SelectMealFilter>
                <SelectMealFilter options={proteinOptions} setFilter={setProteinFilter} selectedFilterItems={proteinFilter}></SelectMealFilter>
                <SelectMealFilter options={carbOptions} setFilter={setCarbFilter} selectedFilterItems={carbFilter}></SelectMealFilter>
            </Container>
            <Container>
                {
                    itemsToDisplay.sort(sortFunc).map((item, index) => (
                        <Row key={index}>
                            <Button className='m-1' variant='light' key={index} onClick={() => props.onItemSelected(item)}>
                                {item.name} - {item.cuisine} - {item.protein}
                            </Button>
                        </Row>
                    ))}
            </Container>
        </>
    );

}


export default SelectMealModalContent;
