import React from 'react';
import Modal from 'react-bootstrap/Modal';
import AddRecipeForm from './AddRecipeForm';

function AddRecipeModal(props) {
    return (
        <Modal {...props} className='py-5' aria-labelledby="contained-modal-title-vcenter" >

            <Modal.Dialog>
                <Modal.Header closeButton>
                    <Modal.Title>Add a Recipe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddRecipeForm {...props} />
                </Modal.Body>
            </Modal.Dialog >
        </Modal >
    );
}

export default AddRecipeModal;
