import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import SignInModal from './components/modal/SignInModal';
import RecipeList from './components/RecipeList';
import constants from './constants'
import ReactGA from 'react-ga4';
import Feedback from '@betahuhn/feedback-js'

function App() {

  new Feedback({ endpoint:  process.env.NODE_ENV !== "production" ? 'http://localhost:3001/feedback' : 'https://recipez.co.uk/feedback' }).renderButton()

  ReactGA.initialize('G-5WS9P38Z5R', {testMode: process.env.NODE_ENV !== "production"});
  
  const [homes, setHomes] = useState([]);
  const [users, setUsers] = useState([]);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const cookies = new Cookies();

  const { USER_ID_COOKIE_PARAM, HOME_ID_COOKIE_PARAM } = constants.cookieNames;

  axios.interceptors.request.use((config) => {
    config.params = config.params || {};
    const homeId = cookies.get(HOME_ID_COOKIE_PARAM);

    if (homeId) {
      config.params['home'] = homeId;
    }
    return config;
  });

  function getHomes() {
    axios.get('/homes')
      .then(response => {
        const homes = response.data;
        setHomes(homes);
      })
      .catch(error => console.error('Error fetching homes:', error));
  }

  function getUsers() {
    axios.get('/users')
      .then(response => {
        const users = response.data;
        setUsers(users);
      })
      .catch(error => console.error('Error fetching users:', error));
  }

  useEffect(() => {
    if (currentUser && currentUser.home) {
      cookies.set(HOME_ID_COOKIE_PARAM, currentUser.home);
    }
  }, [currentUser]);

  useEffect(() => {
    const userId = cookies.get(USER_ID_COOKIE_PARAM);
    if (userId) {
      setCurrentUser(users.find(user => user._id === userId));
    }
  }, [users]);

  useEffect(() => {
    getUsers();
    getHomes();

    if (cookies.get(USER_ID_COOKIE_PARAM)) {
      setIsSignedIn(true);
    }
  }, []);

  const handleSignIn = (userId) => {
    // TODO: this is getting cancelled before it can send.
    ReactGA.gtag('event', 'login');
    cookies.set(USER_ID_COOKIE_PARAM, userId);
    setCurrentUser(users.find(user => user._id === cookies.get(USER_ID_COOKIE_PARAM)))
  }

  const handleCreateUser = async (name, home) => {
    await axios.post('/user', { name, home })
      .then(response => {
        ReactGA.gtag('event', 'create_user');
        cookies.set(USER_ID_COOKIE_PARAM, response.data._id);
      })

    return cookies.get(USER_ID_COOKIE_PARAM);
  }

  return (
    <div className="App mt-2 mb-5">
      {!isSignedIn && (
        <SignInModal
          show={!isSignedIn}
          users={users}
          homes={homes}
          onSignIn={handleSignIn}
          onCreateUser={handleCreateUser}
        />
      )}
      {currentUser && currentUser.home && (
        <RecipeList/>
      )}
    </div>
  );
}

export default App;
