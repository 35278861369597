export default Object.freeze({
    regenerateButtonEnabled  :false,
    addRecipeFloatingButton : false,
    signOutButton : false,
    getIngredientsButton : true,
    addRecipeBottomNavBar : false,
    addRecipeTopNavBar : true,
    refreshMenuOptions : false,
    recipeLinkButton: false,
    bottomPills: false,
    lockRecipe: false,
    enableWebSockets: false,
})