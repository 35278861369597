import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { XLg } from "react-bootstrap-icons";
// Define a functional component named UploadAndDisplayImage
const UploadAndDisplayImage = (  {selectedImage, setSelectedImage}) => {
  // Return the JSX for rendering
  return (
    <div>
      {selectedImage && (
        <div>
          {/* Display the selected image */}
          <img
            alt="not found"
            width={"250px"}
            src={URL.createObjectURL(selectedImage)}
          />
          {/* Button to remove the selected image */}
          <Button variant="danger" onClick={() => setSelectedImage(null)}><XLg/></Button>
        </div>
      )}

      <br />

      <input
      className="btn-primary"
        type="file"
        name="myImage"
        // Event handler to capture file selection and update the state
        onChange={(event) => {
          console.log(event.target.files[0]); // Log the selected file
          setSelectedImage(event.target.files[0]); // Update the state with the selected file
        }}
      />
    </div>
  );
};

export default UploadAndDisplayImage;