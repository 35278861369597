import React from 'react';
import ImageCardView from './ImageCardView';

function Item({ ...props }) {
    return (
        <ImageCardView {...props} />
    );
}

export default Item;
