import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Tab, Tabs } from 'react-bootstrap';
import SelectMealModalContent from './modal/content/SelectMealModalContent';
import SelectableStringModalContent from './modal/content/SelectableStringModalContent';
import AddRecipeForm from './AddRecipeForm';

function EditModal(props) {


    return (
        <Modal {...props} className='py-5' aria-labelledby="contained-modal-title-vcenter" >

            <Modal.Dialog>
                <Modal.Header closeButton>
                    <Modal.Title>Select a Meal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        defaultActiveKey={"meal"}
                        id='single-meal-picker'
                        className='mb-3'>
                        <Tab eventKey={"meal"} title="Meal">
                            <SelectMealModalContent {...props} />
                        </Tab>
                        <Tab eventKey={"cuisine"} title="Cuisine">
                            <SelectableStringModalContent {...props} onCategorySelect={props.onCategorySelect('cuisine')} strings={[...new Set(props.items.map(item => item.cuisine))]} />
                        </Tab>
                        <Tab eventKey={"protein"} title="Protein">
                            <SelectableStringModalContent {...props} onCategorySelect={props.onCategorySelect('protein')} strings={[...new Set(props.items.map(item => item.protein))]} />
                        </Tab>
                        <Tab eventKey={"addRecipe"} title="Add Recipe">
                            <AddRecipeForm {...props} />
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal.Dialog >
        </Modal >
    );
}

export default EditModal;
