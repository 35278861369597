import React from 'react';
import Button from 'react-bootstrap/Button';
import { ArrowClockwise, ArrowLeftRight, Link45deg, LockFill, UnlockFill } from 'react-bootstrap-icons';
import features from '../features';

const ButtonRow = ({ item, onShuffle, onEdit, onLock }) => {
    return (
        <div className="align-self-center buttons">
            <Button className='mx-1 btn btn-danger' disabled={item.locked} onClick={onShuffle}>
                <ArrowClockwise />
            </Button>
            <Button className='mx-1 btn btn-primary' disabled={item.locked} onClick={onEdit}>
                <ArrowLeftRight />
            </Button>

            {features.lockRecipe && <Button variant='outline-primary' className='mx-1' onClick={onLock}>
                {item.locked ?
                    <LockFill className="ml-4" /> :
                    <UnlockFill />
                }
            </Button>}
            
            {features.recipeLinkButton &&
                <Button variant='outline-primary' className='mx-1' href={item.url}>
                    <Link45deg />
                </Button>
            }
        </div>
    )
}

export default ButtonRow;
